import ThirdParty, { Google, LinkedIn } from "supertokens-auth-react/recipe/thirdparty";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";

export function getApiDomain() {
    return 'https://auth.anygrid.com';
    // const apiPort = process.env.REACT_APP_API_PORT || 3001;
    // const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
    // return apiUrl;
}

export function getWebsiteDomain() {
    return 'https://anygrid.com';
    // const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    // const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
    // return websiteUrl;
}

export const SuperTokensConfig = {
    appInfo: {
        appName: "AnyGrid",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain(),
    },
    getRedirectionURL: async (context: any) => {
        if (context.action === "SUCCESS" && context.newSessionCreated) {
            if (context.redirectToPath !== undefined) {
                // we are navigating back to where the user was before they authenticated
                return context.redirectToPath;
            }
            return "/app/gbp";
        }
        return undefined;
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        EmailPassword.init(),
        EmailVerification.init({
            mode: "REQUIRED", // or "OPTIONAL"
        }),
        ThirdParty.init({
            signInAndUpFeature: {
                providers: [Google.init(), LinkedIn.init()],
            },
        }),
        Session.init(),
    ],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/thirdpartyemailpassword/introduction",
};

export const PreBuiltUIList = [ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI];

export const ComponentWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return props.children;
};
